import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { File } from "../../components/File";
import { Files } from "../../components/Files";
import { FlexWrap } from "../../components/FlexWrap";
import { Page } from "../../components/Page";
import { H3 } from "../../components/Typography";
import { Videos } from "../../components/Videos";
import { useAppDispatch, useAppSelector } from "../../state/configureStore";
import {
    fetchAssets,
    selectAssetsGroupedByCategory,
    selectRequestStatus,
} from "../../state/slices/assetsSlice";
import { selectCurrentPlanogram, selectFuturePlanograms } from "../../state/slices/customerSlice";
import { planogramPdf, selectPlanogramPdfLoading } from "../../state/slices/pdfSlice";
import { selectLocale } from "../../state/slices/userSlice";
import { Asset, AssetCategory, RequestStatus } from "../../state/types";

const useStyles = makeStyles({
    col: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "33.3%",
        padding: "0 10px",
    },
    documentFiles: {
        "& .file": {
            marginTop: 10,
        },
    },
    title: {
        marginBottom: 10,
    },
    controls: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        paddingBottom: 10,
    },
    divider: {
        marginTop: 30,
        marginBottom: 30,
    },
});

export const GuidancePage = () => {
    const request = useAppSelector(selectRequestStatus);
    const assets = useAppSelector(selectAssetsGroupedByCategory);
    const locale = useAppSelector(selectLocale);
    const currentPlanogram = useAppSelector(selectCurrentPlanogram);
    const futurePlanograms = useAppSelector(selectFuturePlanograms);
    const planogramPdfLoading = useAppSelector(selectPlanogramPdfLoading);
    const dispatch = useAppDispatch();
    const { t } = useTranslation("guidance");
    const classes = useStyles();

    useEffect(() => {
        void dispatch(fetchAssets());
    }, [dispatch, locale]);

    return (
        <Page loading={request === RequestStatus.Loading && Object.keys(assets).length === 0}>
            <H3 className={classes.title}>{t("guidance")}</H3>
            <Videos videosPerRow={5} assets={assets[AssetCategory.instructionVideos]} />

            <FlexWrap
                items={assets[AssetCategory.guidance]}
                itemsPerRow={3}
                rowKey={(asset: Asset) => asset.assetId.toString()}
                renderRow={(asset: Asset) => <File asset={asset} />}
                spacing={10}
            />

            <Divider className={classes.divider} />

            <FlexWrap
                items={[
                    {
                        key: "planogram",
                        title: t("planogram"),
                        assets: [
                            currentPlanogram
                                ? {
                                      assetId: "currentPlanogram",
                                      name: t("currentPlanogram"),
                                      ext: ".pdf",
                                      type: "file",
                                      onClick: () =>
                                          void dispatch(planogramPdf(currentPlanogram.start)),
                                      loading: planogramPdfLoading,
                                  }
                                : undefined,
                            ...futurePlanograms.map(p => ({
                                assetId: `future-planogram-${p.start}`,
                                name: t("futurePlanogram", { start: p.start }),
                                ext: ".pdf",
                                type: "file",
                                onClick: () => void dispatch(planogramPdf(p.start)),
                                loading: planogramPdfLoading,
                            })),
                        ].filter(Boolean),
                    },
                    {
                        key: "label",
                        title: t("labels"),
                        assets: assets[AssetCategory.labels],
                    },
                    {
                        key: "deviationAndClaim",
                        title: t("deviationAndClaims"),
                        assets: assets[AssetCategory.deviationAndClaims],
                    },
                    { key: "risks", title: t("risks"), assets: assets[AssetCategory.risks] },
                ].filter(row => (row.assets ?? []).length > 0)}
                itemsPerRow={3}
                rowKey={(row: { key: string }) => row.key}
                renderRow={(row: { title: string; assets: Asset[] }) => (
                    <Files
                        title={row.title}
                        assets={row.assets}
                        className={classes.documentFiles}
                    />
                )}
                spacing={10}
            />

            <Divider className={classes.divider} />

            <H3 className={classes.title}>{t("certificates")}</H3>

            <FlexWrap
                items={assets[AssetCategory.certificatesAndCompliance]}
                itemsPerRow={3}
                rowKey={(asset: Asset) => asset.assetId.toString()}
                renderRow={(asset: Asset) => <File asset={asset} />}
                spacing={10}
            />
        </Page>
    );
};
