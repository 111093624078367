import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Body1 } from "../../../components/Typography";
import { colors } from "../../../constants/colors";
import { useAppDispatch, useAppSelector } from "../../../state/configureStore";
import { selectDeliveryDatesByWeek } from "../../../state/slices/deliveryDatesSlice";
import { changeDeliveryDate, selectDeliveryDate } from "../../../state/slices/orderDetailsSlice";
import {
    getOrderMatForWeek,
    selectOrderMat,
    updateOrderMatProduct,
    Week,
} from "../../../state/slices/orderMatsSlice";
import { Product } from "../../../state/types";
import { ThemeType } from "../../../theme";
import { DebouncedTextField } from "../quantity/DebouncedTextField";

const useStyles = makeStyles<ThemeType, { highlighted: boolean; disabled: boolean }>({
    qtyInput: ({ highlighted, disabled }) => ({
        borderRadius: "0.5rem",
        border: `2px ${disabled ? colors.grey : colors.heroGreen} solid`,
        fontSize: 18,
        fontWeight: "bold",
        height: "100%",
        lineHeight: "100%",
        margin: 0,
        padding: 0,
        textAlign: "center",
        width: "50%",
        backgroundColor: highlighted ? colors.heroGreenExtraLight : undefined,
    }),
    qtyLastWeek: {
        marginLeft: "0.5rem",
    },
});

type Props = {
    product: Product;
    dayOfWeek: number;
    selectedWeek: Week;
    startOfSelectedWeek: DateTime;
};

export const OrderMatCell: FC<Props> = ({
    product,
    dayOfWeek,
    selectedWeek,
    startOfSelectedWeek,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("order");

    const deliveryDate = useAppSelector(selectDeliveryDate);
    const orderMat = useAppSelector(selectOrderMat);
    const deliveryDates = useAppSelector(state =>
        selectDeliveryDatesByWeek(state, product.assortments),
    );
    const orderMatItems = orderMat(product.productId, dayOfWeek);
    const quantityLastWeek = orderMatItems[0]?.quantityLastWeek;
    const deadlines = (
        deliveryDates.find(dd => DateTime.fromISO(dd.deliveryDate).weekday === dayOfWeek)
            ?.assortments ?? []
    ).map(deliveryAssortment => DateTime.fromISO(deliveryAssortment.deadline));
    const disabled = !deadlines.length || deadlines.some(d => d < DateTime.now());

    const handleChange = async (quantity: number) => {
        const deliveryDate = startOfSelectedWeek.plus({ days: dayOfWeek - 1 }).toISODate();
        await dispatch(
            updateOrderMatProduct({ productId: product.productId, deliveryDate, quantity }),
        );
        await dispatch(getOrderMatForWeek(selectedWeek));
    };

    const highlighted =
        startOfSelectedWeek.plus({ days: dayOfWeek - 1 }).toISODate() === deliveryDate;
    const classes = useStyles({ highlighted, disabled });

    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <DebouncedTextField
                onQuantityChange={async newQuantity => handleChange(newQuantity)}
                className={classes.qtyInput}
                disabled={disabled}
                quantity={disabled ? undefined : orderMatItems[0]?.quantity ?? 0}
                placeholderValue="-"
                onFocus={() => {
                    const selectedDate = startOfSelectedWeek
                        .plus({ days: dayOfWeek - 1 })
                        .toISODate();
                    dispatch(changeDeliveryDate(selectedDate));
                }}
                min={0}
                max={99}
            />
            {quantityLastWeek !== undefined && (
                <Body1 className={classes.qtyLastWeek}>
                    {t("productTable.quantityLastWeek", { quantityLastWeek })}
                </Body1>
            )}
        </Box>
    );
};
