import "./appPolyfill";
import "./configureI18n";
import CssBaseLine from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDom from "react-dom";
import { Provider } from "react-redux";
import { App } from "./App";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { SnackbarProvider } from "./components/snackbar/SnackbarProvider";
import { config } from "./config";
import { axiosInstance } from "./network/createInstance";
import { setupHTTPInterceptors } from "./network/httpInterceptors";
import { asyncPolyfill } from "./polyfill";
import { store } from "./state/configureStore";
import { theme } from "./theme";

Sentry.init({
    dsn: "https://8ca3d9f30d7442748cb770a23d39e749@o1286767.ingest.sentry.io/6501229",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({
            levels: ["error"],
        }),
    ],
    ignoreErrors: [
        /StrictMode/, // Strict mode is only activated in development - no need to log this
    ],
    environment: config.environment,
    normalizeDepth: 5,
    tracesSampleRate: 0.2,
});

setupHTTPInterceptors(axiosInstance);

asyncPolyfill()
    .catch((err: unknown) => {
        console.error(`Failed to polyfill ${err}`);
    })
    .finally(() => {
        ReactDom.render(
            <React.StrictMode>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider>
                            <CssBaseLine />
                            <ErrorBoundary>
                                <App />
                            </ErrorBoundary>
                        </SnackbarProvider>
                    </ThemeProvider>
                </Provider>
            </React.StrictMode>,
            document.getElementById("root"),
        );
    });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
