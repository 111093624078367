import { ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GenericFileIcon from "@material-ui/icons/Description";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import cn from "classnames";
import React, { FC } from "react";
import { Asset } from "../state/types";
import { GtmEvents, pushDataLayer } from "../utils/pushDataLayer";
import { Loader } from "./Loader";
import { Body1 } from "./Typography";

const useStyles = makeStyles({
    file: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "#EDEFEE",
        borderRadius: 40,
        height: 44,
        padding: 10,
        cursor: "pointer",
        textAlign: "left",
        width: "100%",
    },
    icon: {
        margin: "13px 14px 13px 22px",
        fontSize: 18,
    },
    loading: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
});

type Props = {
    asset: Omit<Asset, "category" | "url" | "mime"> & { url?: string };
    onClick?: () => void;
    loading?: boolean;
};

export const File: FC<Props> = ({ asset, onClick, loading }) => {
    const classes = useStyles();

    return (
        <ButtonBase
            className={cn(classes.file, "file")}
            onClick={() => {
                pushDataLayer(GtmEvents.File, {
                    cmsAssetId: asset.assetId,
                    fileName: asset.name,
                    fileUrl: asset.url,
                });

                if (onClick) {
                    onClick();
                } else if (asset.url) {
                    window.open(asset.url, "_blank");
                }
            }}
            disabled={loading}
        >
            {loading ? (
                <Loader size={20} className={classes.loading} show={loading} delay={0} />
            ) : (
                <>
                    {asset.type === "video" ? (
                        <OndemandVideoIcon className={classes.icon} />
                    ) : asset.ext === ".pdf" ? (
                        <PictureAsPdfIcon className={classes.icon} />
                    ) : (
                        <GenericFileIcon className={classes.icon} />
                    )}
                    <Body1>{asset.name}</Body1>
                </>
            )}
        </ButtonBase>
    );
};
